import { APIBaseUrl } from '../organon/settings';


export function fetchUsersByName (query) {
  const promise = new Promise((resolve, reject) => {
    fetch(`${APIBaseUrl}/api/users/?search=${query}`, { 'method': 'GET', 'credentials': 'include' })
      .then(r => {
        r.json()
          .then(data => resolve(data))
          .catch(reject);
      })
      .catch(reject)
  });


  return promise;
}


export default { fetchUsersByName }