import { createSlice } from '@reduxjs/toolkit';
import { fetchScoreById } from '../score/scoreSlice';

const initialState = {
  // hack, hack, let's put some tape
  // Depending on the location define initial state to display the score
  // or the index. Needs to be replaced with proper routing.
  panel: document.location.pathname.startsWith('/score/') ? 'score' : 'index' // index || score
};

export const organonSlice = createSlice({
  name: 'organon',
  initialState,
  reducers: {
    setPanel: (state, action) => {
      state.panel = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchScoreById.pending, (state, action) => {
      state.panel = 'score'
    });
  }
});



export function selectPanel (state) {
  return state.organon.panel;
}

export const { setPanel } = organonSlice.actions;

export default organonSlice.reducer